<template>
    <div class="view-feedbacks-organizer" v-if="translates && translates[langUrl]">
        <nav v-if="isDesktop && translates.navigation" class="navigation">
            <div class="link" @click="mainPage">{{ translates.navigation.button_route_main[lang] }} ></div>
            <div class="link" @click="userFeedbacks">{{ translates.navigation.button_route_userFeedbacks[lang] }}</div>
        </nav>
        <h1 class="view-feedbacks-organizer__title">{{ translates[langUrl].pageTitle[lang] }}</h1>
        <div class="view-feedbacks-organizer__content">
            <div v-if="feedbacks.length < 1" class="view-feedbacks-organizer__content__empty">
                {{ translates[langUrl].noFeedbacks[lang] }}
            </div>
            <template v-for="(feedback, i) in feedbacks">
                <div class="view-feedbacks-organizer__feedback" :key="'feedback' + i">
                    <div class="view-feedbacks-organizer__feedback__date">
                        {{ feedback.createdAt | moment('DD MMM YYYY') }}
                    </div>
                    <div class="view-feedbacks-organizer__feedback__trip" v-if="feedback.trip">
                        {{ feedback.trip.name }}
                    </div>
                    <div class="view-feedbacks-organizer__feedback__user">
                        <div v-if="feedback.name">
                            {{ feedback.name }}
                            <div v-if="feedback.user" class="view-feedbacks-organizer__feedback__user__registered">
                                <v-icon color="green" class="mr-2">mdi-check</v-icon> {{ feedback.user.firstname }} {{ feedback.user.lastname }}
                            </div>
                        </div>
                        <div v-if="feedback.phone">
                            {{ feedback.phone }}
                        </div>
                        <div v-if="feedback.email">
                            {{ feedback.email }}
                        </div>
                    </div>
                    <v-spacer/>
                    <div class="view-feedbacks-organizer__feedback__info">
                        <div v-if="feedback.link">
                            <a :href="feedback.link">{{ feedback.link }}</a>
                        </div>
                        <div v-if="feedback.comment">
                            {{ feedback.comment }}
                        </div>
                    </div>
                    <BaseButton
                        class="button"
                        style="width: 100%"
                        @click="deleteFeedback(feedback)">{{ translates[langUrl].button_deleteFeedback[lang] }}</BaseButton>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { BOOKING_STATUS } from '@/vars';
    import BaseButton from '../../components/common/BaseButton';
    import { isMobile } from '@/helpers';

    export default {
        name: 'FeedbacksOrganizer',
        metaInfo: {
            title: 'Обращения по турам'
        },
        components: {
            BaseButton
        },
        data: () => ({
            BOOKING_STATUS,
            isDesktop: false,
            langUrl: '/user/feedbacks/organizer'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('feedbacks', {
                feedbacks: state => state.entities
            }),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        },
        mounted() {
            store.dispatch('feedbacks/fetchOrganizer', {});
            this.onResize();
        },
        methods: {
            async deleteFeedback(feedback) {
                await store.dispatch('feedbacks/delete', { id: feedback._id });
                await store.dispatch('feedbacks/fetchOrganizer', {});
            },
            onResize() {
                this.isDesktop = !isMobile();
            },
            async userFeedbacks () {
                await this.$router.push({ name: 'user-feedbacks-organizer' });
            },
            async mainPage () {
                await this.$router.push({ name: 'home' });
            },
        }
    }
</script>

<style lang="scss">
    .view-feedbacks-organizer {
        @media all and (min-width: 768px) {
            padding: 0 32px;
        }
        @media all and (min-width:1464px) {
            padding: 0;
        }
        &__menu{
            margin: 13px 24px 0;
            font-size: 12px;
            line-height: 140%;
            letter-spacing: -0.02em;
            color: #44414D;
            @media all and (min-width:768px) {
                margin: 13px 0 0;
            }
        }
        &__title {
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            color: #3A4256;
            margin: 32px 24px 12px;
            @media all and (min-width:768px) {
            font-weight: bold;
            font-size: 38px;
            line-height: 46px;
            color: #273155;
            margin: 40px 0 36px;
            }
        }
        &__content {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            
            @media all and (min-width: 768px) {
                flex-direction: row;
                margin-right: -32px;
            }

            &__empty {
                font-weight: bold;
                font-size: 18px;
                line-height: 140%;
                color: #3A4256;
                text-align: center;
                margin: 10vw auto;
            }
        }
        &__feedback{
            display: flex;
            flex-direction: column;
            padding: 24px;
            box-shadow: 0px 4px 20px rgba(44, 47, 56, 0.08);
            border-radius: 10px;
            margin-bottom: 32px;
            @media all and (min-width: 768px) {
                width: 326px;
                margin-right: 32px;
                // &:last-of-type {
                //     margin-right: 0;
                // }
            }
            &__date {
                font-size: 16px;
                line-height: 18px;
                letter-spacing: -0.02em;
                color: #3A4256;
                margin-bottom: 14px;
            }
            &__trip {
                font-size: 14px;
                line-height: 24px;
                letter-spacing: -0.02em;
                color: #3A4256;
                margin-bottom: 14px;
            }
            &__user {
                font-weight: bold;
                font-size: 14px;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: #3A4256;
                margin-bottom: 14px;

                > div {
                    margin-bottom: 5px;
                }
                &__registered {
                    color: green;
                }
            }
            &__info {
                padding-top: 14px;
                margin-bottom: 14px;
                border-top: 1px solid #F6F7F9;
                font-size: 12px;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: #9FA5AD;
                font-weight: 600;
                > div {
                    margin-bottom: 5px;
                }
            }
        }
    }
</style>